// 
// _horizontal.scss
// 

.topnav {
    background: $topnav-bg;
    padding: 0 calc(#{$grid-gutter-width} / 2);
    box-shadow: $box-shadow;
    margin-top: $header-height;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 100;
    
    .topnav-menu {
        margin: 0;
        padding: 0;
    }

    .navbar-nav {
        
        .nav-link {
            font-size: 15px;
            position: relative;
            padding: 1rem 1.3rem;
            color: $menu-item-color;
            font-family: $font-family-secondary;

            i{
                font-size: 15px;
                vertical-align: middle;
                display: inline-block;
            }
            &:focus, &:hover{
                color: $menu-item-active-color;
                background-color: transparent;
            }
        }
        
        .dropdown-item{
            color: $menu-item-color;
            &.active, &:hover{
                color: $menu-item-active-color;
            }
        }
        
        .nav-item{
            .nav-link.active{
                color: $menu-item-active-color;
            }
        }

        .dropdown{
            &.active{
              >a {
                    color: $menu-item-active-color;
                    background-color: transparent;
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {

    body[data-layout="horizontal"] {
        .container-fluid,
        .navbar-header {
            max-width: 85%;
        }
    }
}

@include media-breakpoint-up(lg) {
    .topnav {
        .navbar-nav {
            .nav-item {
                &:first-of-type {
                    .nav-link {
                        padding-left: 0;
                    }
                }
            }
        }

        .dropdown-item {
            padding: .5rem 1.5rem;
            min-width: 180px;
        }

        .dropdown {
            &.mega-dropdown{
                // position: static;
                .mega-dropdown-menu{
                    left: 0px;
                    right: auto;
                }
            }
            .dropdown-menu {
                margin-top: 0;
                border-radius: 0 0 $dropdown-border-radius $dropdown-border-radius;

                .arrow-down {
                    &::after {
                        right: 15px;
                        transform: rotate(-135deg) translateY(-50%);
                        position: absolute;
                    }
                }

                .dropdown {
                    .dropdown-menu {
                        position: absolute;
                        top: 0 !important;
                        left: 100%;
                        display: none
                    }
                }
            }

            &:hover {
                >.dropdown-menu {
                    display: block;
                }
            }
        }

        .dropdown:hover>.dropdown-menu>.dropdown:hover>.dropdown-menu {
            display: block
        }
    }

    .navbar-toggle {
        display: none;
    }
}

.arrow-down {
    display: inline-block;

    &:after {
        border-color: initial;
        border-style: solid;
        border-width: 0 0 1px 1px;
        content: "";
        height: .4em;
        display: inline-block;
        right: 5px;
        top: 50%;
        margin-left: 10px;
        transform: rotate(-45deg) translateY(-50%);
        transform-origin: top;
        transition: all .3s ease-out;
        width: .4em;
    }
}


@include media-breakpoint-down(xl) {
    .topnav-menu {
        .navbar-nav {
            li {
                &:last-of-type {
                    .dropdown {
                        .dropdown-menu {
                            right: 100%;
                            left: auto;
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(lg) {

    .navbar-brand-box{
        .logo-dark {
            display: $display-block;
            span.logo-sm{
                display: $display-block;
            }
        }
    
        .logo-light {
            display: $display-none;
        }
    }

    .topnav {
        max-height: 360px;
        overflow-y: auto;
        padding: 0;
        .navbar-nav {
            .nav-link {
                padding: 0.75rem 1.1rem;
            }
        }

        .dropdown {
            .dropdown-menu {
                background-color: transparent;
                border: none;
                box-shadow: none;
                padding-left: 15px;
                &.dropdown-mega-menu-xl{
                    width: auto;
    
                    .row{
                        margin: 0px;
                    }
                }
            }

            .dropdown-item {
                position: relative;
                background-color: transparent;

                &.active,
                &:active {
                    color: $primary;
                }
            }
        }

        .arrow-down {
            &::after {
                right: 15px;
                position: absolute;
            }
        }
    }
}


@include media-breakpoint-up(lg) {

    body[data-layout="horizontal"][data-topbar="light"] {
        .navbar-brand-box{
            .logo-dark {
                display: $display-block;
            }
        
            .logo-light {
                display: $display-none;
            }
        }

        .topnav{
            background-color: $header-dark-bg;
            .navbar-nav {
        
                .nav-link {
                    color: rgba($white, 0.7);
                    
                    &:focus, &:hover{
                        color: rgba($white, 0.9);
                    }
                }

                > .dropdown{
                    &.active{
                    >a {
                            color: rgba($white, 0.9) !important;
                        }
                    }
                }
            }
        }
    }
}